import {
  ADD_PAYMENT,
  EDIT_PAYMENT,
  DELETE_PAYMENT,
  GET_PAYMENTS,
  FIND_PAYMENT,
  GET_GATEWAYS
} from "../types";
import fetchAPIClass from "../../fetchAPI";
import { restfulUrl, API_URL, PAYMENT_SETTING_URL } from "context/url";

export const getPayments = (data) => (dispatch) => {
  let url = `${PAYMENT_SETTING_URL}/methods/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_PAYMENTS);
    api.fetchAPI(dispatch, data);
  } catch (err) {
    console.log(err);
  }
};

export const getPaymentGateways = (data) => (dispatch) => {
  let url = `${PAYMENT_SETTING_URL}/gateways`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_GATEWAYS);
    api.fetchAPI(dispatch, data);
  } catch (err) {
    console.log(err);
  }
};

export const addPayment = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${PAYMENT_SETTING_URL}/methods`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_PAYMENT);
    let response = api.fetchAPI(dispatch, inputs);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deletePayment = (paymentId) => (dispatch) => {
  let url = `https://payment.staging.uxapp360.com/api/payments/${paymentId}`;
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_PAYMENT);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editPayment = (inputs, paymentId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${PAYMENT_SETTING_URL}/methods`;

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_PAYMENT);
    let response = api.fetchAPI(dispatch, inputs);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};